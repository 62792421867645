.code {
    width: 100%;
    height: auto;
}
.code-home {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/art.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.code {
    width: 100%;
    height: 100vh;
    /* background-color: rgba(255, 255, 255, 0.4); */
    background-color: rgba(0,0,0, 0.8);
}

.code h1 {
    text-align: center;
    color: pink;
    padding: 50px;
    text-transform: uppercase;
}

.code-desc {
    width: 35%;
    border: 1px pink solid;
    position: absolute;
    top: 50%;
    left: 50px;
    color: #fff;
    box-shadow: 20px, 20px, pink;
    padding: 10px;
    text-indent: 30px;
}
.code-desc p::first-letter, .code-right-desc p::first-letter {
    font-size: 50px;
    font-style: italic;
}
.code-right-desc {
    width: 35%;
    border: 1px pink solid;
    position: absolute;
    top: 50%;
    right: 50px;
    color: #fff;
    box-shadow: 20px, 20px, pink;
    padding: 30px;
    text-indent: 30px;
}
/* code page 2 */
.code-about {
    width: 100%;   
    height: auto;
}

.code-about .col p {
    text-align: justify;
    padding:  0 30px;
}

.code-about .col h1 {
    text-align: center;
    color: #802652;
}
.code-about .col:last-child {
    padding: 0;
}
.code-about .col:last-child {
    width: 100%;
    height: 100%;
}
.code-about .col:last-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 0 0 0;
}