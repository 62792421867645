.gymnastics {
    width: 100%;
    height: auto;
}
.gymnastics-home {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/gymnastic.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.gymnastics h1 {
    text-align: center;
    color: #fff;
    padding: 40px;
}

.gymnastics-desc {
    width: 30%;
    border-radius: 25px;
    position: absolute;
    top: 40%;
    left: 50px;
    color: black;
    padding: 20px;
    text-indent: 30px;
    background-color: #802652;
    text-align: justify;
}
.gymnastics-desc p::first-letter, .gymnastics-right-desc p::first-letter {
    font-size: 50px;
    font-style: italic;
}
.gymnastics-right-desc {
    width: 30%;
    border-radius: 25px;
    position: absolute;
    top: 40%;
    right: 50px;
    color: black;
    background-color: #802652;
    padding: 15px;
    text-indent: 30px;
}
/* gymnastics page 2 */
.gymnastics-about {
    width: 100%;   
    height: auto;
}

.gymnastics-about .col p {
    text-align: justify;
    padding:  0 30px;
}

.gymnastics-about .col h1 {
    text-align: center;
    color: #802652;
}
.gymnastics-about .col:last-child {
    padding: 0;
}
.gymnastics-about .col:last-child {
    width: 100%;
    height: 100%;
}
.gymnastics-about .col:last-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 0 0 0;
}