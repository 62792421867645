.ballet {
    width: 100%;
    height: auto;
}
.ballet-home {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/ballet-girls.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.ballet h1 {
    text-align: center;
    color: pink;
    padding: 50px;
}

.ballet-desc {
    width: 20%;
    border: 1px pink solid;
    position: absolute;
    top: 50%;
    left: 50px;
    color: #fff;
    box-shadow: 20px, 20px, pink;
    padding: 15px;
    text-indent: 30px;
}
.ballet-desc p::first-letter, .ballet-right-desc p::first-letter {
    font-size: 50px;
    font-style: italic;
}
.ballet-right-desc {
    width: 20%;
    border: 1px pink solid;
    position: absolute;
    top: 50%;
    right: 50px;
    color: #fff;
    padding: 20px;
    text-indent: 30px;
}
/* ballet page 2 */
.ballet-about {
    width: 100%;   
    height: auto;
}

.ballet-about .col p {
    text-align: justify;
    padding:  0 30px;
}

.ballet-about .col h1 {
    text-align: center;
    color: #802652;
}
.ballet-about .col:last-child {
    padding: 0;
}
.ballet-about .col:last-child {
    width: 100%;
    height: 100%;
}
.ballet-about .col:last-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 0 0 0;
}