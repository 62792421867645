.unspoken {
    width: 100%;
    background-color: #802652;
}

.unspoken h1, .unspoken h6 {
    text-align: center;
    color: #802652;
    text-transform: uppercase;
}

.unspoken h6 {
    font-style: italic;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.unspoken-content {
    width: 90%;
    margin: 0 auto;
    padding-top: 50px;
    background-color: #fff;
}

.unspoken-img {
    flex: 1;
    max-width: 500px;
    height: auto;
}

.unspoken-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.unspoken-desc {
    display: flex;
    align-items: center;
    justify-content: center;
}
.unspoken-into {
    flex: 7;
    padding: 30px;
}
.unspoken-image {
    flex: 5;
}
.unspoken-image img {
    border-radius: 0 0 0 1000px;
}

.unspoken-element h1{
    padding-top: 100px;
    color: #802652;
}

.unspoken-desc p {
    text-align: justify;
    margin-bottom: 20px;
    color: #000;
}

.unspoken-images {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    padding-top: 20px;
}
.unspoken-images h1 {
    color: #fff;
    text-align: center;
}
.images {
    flex: 4;
    width: 100%;
    height: 500px;
}
.images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

/* Media Queries for Responsive Layouts */
@media (max-width: 768px) {
    .unspoken-content, .unspoken-desc {
        flex-direction: column;
    }

    .unspoken-desc, .unspoken-img {
        width: 100%;
    }
    .unspoken-images {
        flex-direction: column;
    }
}

@media (max-width: 425px) {
    .unspoken h1 {
        font-size: 24px;
    }

    .unspoken h6 {
        font-size: 16px;
    }

    .unspoken-desc {
        padding: 10px;
    }
}

@media (max-width: 375px) {
    .unspoken h1 {
        font-size: 20px;
    }

    .unspoken h6 {
        font-size: 14px;
    }
}
