/* General Styles */
.awards {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
  }
  .awards h1 {
    color: #802652;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
  }
  .awards h3 {
    text-align: center;
  }
  
  /* Introduction Section */
  .awards-intro {
    text-align: justify;
    padding: 20px 40px;
    color: #333;
    font-size: 1.1rem;
    max-width: 90%;
    margin: 0 auto;
  }
  
  /* Awards Row and Column */
  .award-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; /* Adjust margin */
  }
  
  .award-col {
    flex: 1;
  }
  
  .award-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .award-desc .awards-image {
    flex: 5;
  }
  
  .awards-image img {
    width: 100%;
    height: auto;  /* Ensure image scales responsively */
    border-radius: 25px;
  }
  
  .award-desc p {
    flex: 7;
    padding: 20px;
  }
  
  /* Awards Content Styling */
  #ballet-award, #gymnastics-award, #fine-arts-award {
    padding: 20px;
    background-color: #802652;
    color: #fff;
    margin-left: 200px;
    border-radius: 20px;
    text-align: justify;
  }
  
  #modern-dance-award, #martial-arts-award {
    background-color: #f3f3f3;
    margin-right: 200px;
    border-radius: 20px;
    padding: 20px;
    color: #802652;
    text-align: justify;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .awards-intro {
      font-size: 1rem; /* Smaller text on tablets */
      padding: 20px;
    }
    
    .award-row {
      flex-direction: column;
    }
  
    .award-desc {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .award-desc .awards-image {
      flex: 0 0 100%; /* Image takes full width */
      margin-bottom: 15px;
    }
  
    .award-desc p {
      flex: 0 0 100%; /* Text takes full width */
    }
  
    #ballet-award, #gymnastics-award, #fine-arts-award, #modern-dance-award, #martial-arts-award {
      margin-left: 0;
      margin-right: 0;
      padding: 15px;
    }
  }
  
  @media (max-width: 425px) {
    .awards-intro {
      font-size: 0.95rem;
      padding: 15px;
    }
  
    .award-desc {
      padding: 10px;
    }
  
    .awards-image img {
      max-width: 100%;
      height: auto;
    }
  
    .award-desc p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 375px) {
    .awards-intro {
      font-size: 0.9rem;
      padding: 10px;
    }
  
    .award-row {
      margin-bottom: 20px;
    }
  
    .award-desc p {
      font-size: 0.85rem;
    }
  
    #ballet-award, #gymnastics-award, #fine-arts-award, #modern-dance-award, #martial-arts-award {
      padding: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  @media (max-width: 320px) {
    .awards-intro {
      font-size: 0.85rem;
      padding: 10px;
    }
  
    .award-desc p {
      font-size: 0.8rem;
    }
  
    .awards-image img {
      width: 100%;
    }
  }
  