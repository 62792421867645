.history {
    width: 100%;
    height: auto;
    background-color: #802652;
}

.history h1 {
    text-align: center;
    font-weight: 900;
    font-size: 50px;
    color: #fff;
    padding-top: 50px;
}
.history-cover {
  width: 80%;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 0;
  border-radius: 25px;
}
.history p {
    width: 60%;
    margin: 0 auto;
    color: #000;
    padding: 20px;
    font-size: 16px;
    text-align: justify;
    border-radius: 25px;
}
.history .row {
    display: flex;
    justify-content: space-between; /* Or use space-around/center depending on spacing */
    align-items: flex-start;
    gap: 10px; /* Space between columns */
    padding: 20px;
  }
  
  .history .col {
    display: flex;
    align-items: center;
    text-align: center;
    flex: 1;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  
  .icon {
    margin-bottom: 10px;
  }
  
  .history .title {
    font-size: 1.2rem;
    margin-bottom: 5px;
    width: max-content;
    padding: 5px 20px;
    background-color: #802652;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
  }
  
  .history .desc {
    font-size: 1rem;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .history .row {
      flex-direction: column !important;
    }
    
  }
  @media (max-width: 425px) {
    .history p {
      width: 90%;
    }    
  }