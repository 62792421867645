/* tablet */
@media(max-width: 768px) {
  .menu {
      display: block !important;
  }
.nav-bar {
    display: none;
}
.nav-bar-mobile {
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    line-height: 10px;
    position: absolute;
    top: 30px;
    right: 120px;
    width: 30%;
    height: auto;
    padding: 10px 20px;
    z-index: 999;
    flex-direction: column;
    background-color: black;
}

.nav-bar-mobile a {
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    color: #815EC7;
    cursor: pointer;
    transition: all 0.2s;
    padding-bottom: 20px;
}

.nav-bar-mobile ul {
    width: 100%;
    padding: 20px;
    background-color: black;
}
.nav-bar-mobile a  {
    flex-direction: column;
    width: 100%;
    color: pink;
}
.nav-bar-hide {
    display: none !important;
}

.nav-bar bi {
    color: #fff;
    font-weight: 900;
    font-size: 40px;
    z-index: 999;
}

.nav-bar ul, .nav-bar li,  .nav-bar a  {
    flex-direction: column;
    width: 100%;
    color: #fff;
}
.nav-bar li::after {
    width: 0;
    height: 0;
}
  .home {
    height: auto;
  }
  .nav-bar {
    display: none !important;
  }
  .welcome {
    width: 60% !important;
  }
  .welcome h1 {
    font-size: 22px;
  }
  .welcome p {
    font-size: 14px;
  }
  .typing {
    font-size: 14px !important;
    padding: 5px !important;
  }
  .mid-right {
    position: absolute !important;
    top: 20% !important;
  }
  .carousel-item h5 {
    font-size: 14px !important;
  }
  .carousel-item p {
    font-size: 14px !important;
  }
  /* about */
  #about {
    height: auto !important;
    padding-bottom: 50px;
  }
  #about-desc {
    flex-direction: column;
  }

  /* dance */
  #dance {
    width: 100%;
    height: auto !important;
    background-color: #802652;
  }
  .dance {
    width: 80%;
    /* padding: 50px; */
    margin: 0 auto;
    background-color: #fff;
  }
  #vid .dance-vid {
    width: 100%;
    height: 100%;
  }
  #dance h1 {
    margin-bottom: 10px !important;
    padding: 10px !important;
  }
  #vid {
    background-position: bottom;
  }

  /* success */
  .success {
    margin-top: 200px;
  }
  #stories-col {
    margin-left: 10px !important;
  }
  /* footer */
  .footer {
    margin-top: 800px !important;
  }
  /* ballet */
  .ballet-about .col:last-child  {
    display: none;
  }
  .ballet-right-desc {
    display: none;
  }
  .ballet-desc {
    width: 90% !important;
    left: 10px !important;
  }
  /* Modern */
  .modern-right-desc {
    display: none;
  }
  .modern-desc {
    position: relative !important;
    width: 100% !important;
    margin: 0 auto;
    top: 0 !important;
    left: 0 !important;
    font-size: 14px;
    padding: 5px !important;
  }
  .gymnastics-right-desc {
    display: none;
  }
  .gymnastics-desc {
    width: 100% !important;
    position: relative !important;
    top:0 !important;
    left:0 !important;
    background-color: rgba(128, 38, 82, 0.5) !important;

  }
  .training .row, .festival-about .row {
    flex-direction: column;
  }
  .training .training-cover {
    width: 100%;
    padding: 20px;
  }
  .code-desc {
    width: 48%;
    top: 30%;
    left: 0;
    padding: 5px;
  }
  .code-right-desc {
    width: 48% !important;
    top: 30% !important;
    right: 0 !important;
    padding: 15px !important;
  }

  .unspoken-cover {
    flex-direction: column;
  }

  .unspoken-img {
    display: none;
  }
}
/* mobile: 425px */
@media (max-width: 425px) {
  #carouselExampleCaptions {
    display: none;
  }
  .home-desc {
    padding: 0 !important;
  }
  .welcome {
    width: 90% !important;
  }
  .success .row {
    flex-direction: column;
  }
  #btn {
    margin-top: 20px !important;
  }
  .footer {
    margin-top: 950px !important;
  }
  #dance .col:last-child {
    display: none;
  }
  .contact-info {
    width: 100%;
  }
  .info ul {
    padding: 5px;
    font-size: 14px;
  }
  .footer p{
    font-size: 14px;
  }
  /* ballet */
  .ballet-desc {
    top: 40% !important;
  }


  .code-desc {
    width: 90% !important;
    top: 35% !important;
    left: 20px !important;
    padding: 5px;
    font-size: 14px;
  }
  .code-right-desc {
    width: 90% !important;
    top: 100vh !important;
    left: 20px !important;
    padding: 5px;
    font-size: 14px;
    color: #802652 !important;
  }
  .festival-desc {
    width: 90% !important;
    top: 45% !important;
    left: 20px !important;
    padding: 5px;
    font-size: 14px;
  }
  .festival-right-desc {
    display: none;
  }
}
/* mobile: 375px */
@media (max-width: 375px) {
  #dance {
    /* margin-top: 500px !important; */
  }
  #vid {
    display: none;
  }
  .contact-info {
    width: 100% !important;
  }
  .footer p{
    font-size: 12px;
  }

}
/* mobile: 320px */
@media (max-width: 425px) {
  .typing {
    font-size: 16px;
  }
  #dance {
    /* margin-top: 580px; */
  }
}

.menu {
  color: #fff;
  font-weight: 900;
  font-size: 30px;
  display: none;
}
.nav-bar-mobile {
  display: none;
}
.nav-bar-hide {
  display: none;
}
.home {
    width: 100%;
    height: auto;
    overflow: hidden;
}
/* back vid */
.back-video{
    width: 100%;
    height: 100vh;
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: repeat;
    z-index: -1;
}

@media(min-aspect-ratio: 16/9){
    .back-video{
        width: 100%;
        height: auto;
        position: absolute;
    }
}

@media(max-aspect-ratio: 16/9){
    .back-video{
        width: auto;
        height: 100%;
        position: absolute;
    }
}
.cover {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
}

/* logo */
.logo {
    padding: 20px;
    flex-basis: 9%;
}
.App-logo {
    height: 7vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* header */
  .header {
    position: relative;
    z-index: 999;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 30%, rgba(255, 255, 255, 0));
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 0 20px 50px;
    line-height: 50px;
  }

  .nav-bar {
    width: 100%;
    flex-basis: 96%;
    display: flex;
    justify-content: center;
    line-height: 60px;
  }
  .nav-bar a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    margin-right: 14px;
    display: block;
    font-size: 13px;
  }

  .nav-bar a:hover {
    background: rgba(255, 255, 255, 0.1);
    padding: 0 20px;
    border-radius: 10px;
    text-transform: capitalize;
  }

  .socials {
    flex-basis: 10%;
  }
  .socials i {
    font-size: 20px;
    font-weight: 500;
    padding-left: 30px;
    float: inline-end;
    cursor: pointer;
  }
  .socials a {
    color: #fff;
  }
  .home-desc {
    height: calc(100vh - 100px) !important;
    color: #fff;
    padding: 20px;
    position: relative;
    z-index: 0;
  }

  /* Carousel */
  #carouselExampleCaptions {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;
    width: 100%;
    height: calc(100vh - 50px);
    margin-top: 50px;
  }
  .carousel-inner {
    width: 100%;
    height: 100%;
  }
  .slider {
    width: 30%;
    padding: 10px;
  }
  .carousel-item {
    background-color: rgba(0, 0, 0, 0.2);
    width: 20% !important;
    padding: 10px;
    border-radius: 10px;
  }
  .carousel-item h5 {
    font-size: 20px;
    padding: 5px;
    font-weight: 700;
  }
  .carousel-item p {
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
  }
  .bottom-left {
    position: absolute !important;
    bottom: 40px;
    left: 20px;
    color: white;
    z-index: 10;
  }
  .mid-right {
    position: absolute !important;
    top: 50% !important;
    right: 0;
    left: 75%;
    color: white;
    z-index: 10;
  }
  .center {
    position: absolute !important;
    top: 0;
    left: 20px;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 10;
  }
  .bottom-right {
    position: absolute !important;
    top: 0;
    right: 0;
    color: white;
    z-index: 10;
  }
  .top-right {
    position: absolute !important;
    top: 0;
    left: 75%;
    color: white;
    z-index: 10;
  }
  /* welcome */
  .welcome {
    width: 50%;
    background-color: rgba(0, 0, 0, 0.75);
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
  }
  .welcome p {
    padding: 20px 0;
  }
  
  .typing {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: #EBB7C8;
    border: 1px solid #802652;
    padding: 10px;
    border-radius: 10px;
  }
  /* .learn-more::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background-color: white;
    border-radius: 2px;
  } */
  
  .learn-more {
    color: #802652;
    font-weight: 700;
    padding: 10px 40px;
    border: none;
    outline: none;
    position: relative;
    z-index: 999;
    border-radius: 5px;
    background: linear-gradient(to right, #ff009d, #1fe5ff);
    cursor: pointer;
  }
  .learn-more::before {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 4px;
  background-color: white;
  z-index: -1;
  transition: 200ms;
  }
  .learn-more::after {
  content: attr(data);
  font-size: 16px;
  background: linear-gradient(to left, #00FFA3, #DC1FFF);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms;
  
  }
  .learn-more:hover::before {
  opacity: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  }
  .learn-more:hover::after{
  color: white;
  }

  /* About */
  #about {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: relative;
  }
  .about-cover {
    width: 80%;
    margin: auto;
    position: relative;
    padding-top: 20px;
  }
  .about-cover .col p {
    text-align: justify;
  }
  #numerics p {
    text-align: center;
    color: #802652;
    font-weight: 700;
  }
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    z-index: 999;
  }

  .col {
    flex: 3;
    text-align: center;
  }
  .about-cover h1, #programs h1 {
    padding: 10px 30px;
    border-radius: 10px;
    color: #802652 !important;
    text-align: center;
    margin-bottom: 20px;
  }

  #about .col img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 0 1000px 0;
  }

  /* Dance Festival */
  #dance {
    width: 100%;
    height: 100vh;
    background-color: #802652;
  }
  #dance h1{
    padding: 50px;
    border-radius: 10px;
    color: #fff !important;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  #dance p {
    padding-left: 40px;
    text-align: justify;
    color: #fff;
  }
  #vid {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #vid .dance-vid{
    width: 90%;
    height: auto;
    position: absolute;
    top: -120px;
    right: 0;
    background-repeat: repeat;
    background-size: cover;
    z-index: 1; /* Keep this above the Lamp effect */
    border-radius: 1px 0 0 1000px;
  }
  /* Programs */
  #programs {
    width: 100%;
    height: 100vh;
    background-color: #fff;
  }
  /* Container that holds the card track */
.card-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Track for moving the cards continuously */
.card-track {
  display: flex;
  width: 90%;
  margin: 0 auto;
  animation: scrollCards 40s linear infinite;
}

/* Each card styling */
.card {
  width: 600px;
  height: 80%;
  background-color: #f4f4f4;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.card-image {
  width: 100%;
  height: 50%;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card h3, .card p {
  text-align: center;
  padding: 20px;
}
/* Animation for infinite scrolling */
@keyframes scrollCards {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Pause animation on hover */
.card-track:hover {
  animation-play-state: paused;
}

.about-desc-col {
  flex-basis: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-desc-col img {    
  width: 100%;
  height: 200px; 
  object-fit: cover;
  border-radius: 10px;
}
.about-desc-col h2 {
  flex-basis: 30%;
  text-transform: uppercase;
  font-size: 20px;
  padding-top: 20px;
  color: #fff;
}
.about-desc-col p {
  flex-basis: 30%;
  padding: 5px 30px;
  color: #802652;
  text-align: justify;
}

  /* Contact Us */
  #contact {
    width: 100%;
    height: auto;
    background-color: pink;
}

#contact h1 {
    color: #802652;
    font-size: 35px;
    text-align: center;
    padding: 20px;
}

#contact p {
    text-align: center;
}
.contact-cover {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-cover {
    width: 100%;
    max-height: 50vh;
    flex: 1;
}

.botton-cover {
    width: 100%;
    min-height: 50vh;
    background-color: #fff;
    flex: 1;
}

.contact-info {
    width: 80%;
    height: auto;
    margin: 50px auto;
    background-color: #fff;
    position: relative;
    border-radius: 20px;
}

.contact-col:first-child {
    flex: 4;
    margin: 20px;
}

.contact-inner-col {
    width: 100%;
    padding: 20px;
    background-color: #802652;
    color: #fff;
    border-radius: 20px;
}
.contact-inner-col h1 {
    color: #fff !important;
}

.contact-col:last-child {
    flex: 8;
}
.content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.icon {
    flex: 2;
    padding-bottom: 20px;
}
.info {
    flex: 10;
    padding-bottom: 20px;
}

.info li {
    list-style: none;
}


.info p {
    text-align: left !important;
}
/* form */
.contact-col form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px;
}

#name, #email, #subject {
    border-width: 0 0 1px 0;
}

input  {
  margin: 10px 50px 20px 0;
  padding: 10px;
}

textarea{
  width: 100%;
  padding: 10px;
  border-width: 0 0 1px 0;
}
#btn {
    margin-top: 200px;
    padding: 10px 20px;
    font-weight: 900;
    font-size: 16px;
    color: #fff;
    background-color: #802652;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}
.form {
  padding: 10px;
}
/* Success */
.success {
  width: 100%;
  height: auto;
  background-image: url('../../images/girl.png');
  background-repeat: no-repeat;
  background-position: center;
}
.success h1 {
 color: #802652 !important;
 padding: 20px;
}

.success h3 {
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
 }

#stories-col {
  padding: 20px;
  background-color: #802652;
  color: #fff;
  margin-left: 200px;
  border-radius: 20px;
  text-align: justify;
}
#exchange h3 {
  color: #802652 !important;
  text-align: center;
}
#exchange {
  background-color: #f3f3f3;
  margin-right: 200px;
  border-radius: 20px;
  padding: 20px;
  text-align: justify;
}
/* footer */
.footer {
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #802652;
  line-height: 50px;
  display: flex;
  justify-content: center;
  margin-top: 200px;
}