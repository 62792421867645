.diversity-home {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/diversity.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.diversity-cover {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
}
.diversity-cover .ballet-desc, .diversity-cover .ballet-right-desc{
    background-color: rgba(128, 38, 82, 0.4);
    padding: 5px;
}
.diversity-cover h1 {
    text-align: center;
    line-height: 50vh;
    color: #fff;
}
.diversity-desc {
    width: 100%;
    height: 100vh;
    padding-top: 100px;
}
.diversity-top-desc {
    position: absolute;
    width: 60%;
    height: 300px;
    margin: 0 auto;
    background-color: pink;
    transform: translateX(40%);
}
.diversity-top-desc img {
    position: absolute;
    top: -50px;
    width: 300px;
    height: 400px;
}
.diversity-top-desc p {
    color: #802652;
    font-size: 18px;
    font-weight: 600;
    float: right;
}

.desc {
    width: 400px;
    height: auto;
    position: absolute;
    top: 80px;
    right: 0;
    margin-right: 50px;
}