.services {
    width: 100%;
    height: 100vh;
}

.corevalues h1 {
    text-transform: uppercase;
    text-align: center;
    background-color: #802652 !important;
    width: max-content;
    padding: 10px 40px;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
}

.services-top {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

.services-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.services-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}

.services-col {
    flex-basis: 20%;
    height: 200px;
    padding: 10px;
    box-shadow: 2px 0 10px #802652;
    margin: 10px;
    text-align: center;
}
.services-col:last-child {
    flex-basis: 30%;
}

.services-col h2 {
    color: #802652;
    padding: 20px;
}

.services-col p {
    padding: 10px;
}
.corevalues {
    width: 100%;
    height: auto;
    overflow: hidden;

}
@media (max-width: 768px) {
    .services-row {
        flex-direction: column !important;
        flex-wrap: wrap;
    }

    .services-col {
        width: 80%;
    }
}