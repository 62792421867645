.modern {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/moden-dance.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.modern-home {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
}

.modern h1 {
    text-align: center;
    color: #fff;
    padding: 40px;
}

.modern-desc {
    width: 30%;
    border-radius: 25px;
    position: absolute;
    top: 40%;
    left: 50px;
    color: #fff;
    box-shadow: 20px, 20px, pink;
    padding: 15px;
    text-indent: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: justify;
}
.modern-desc p::first-letter, .modern-right-desc p::first-letter {
    font-size: 50px;
    font-style: italic;
}
.modern-right-desc {
    width: 30%;
    border-radius: 25px;
    position: absolute;
    top: 40%;
    right: 50px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
    text-indent: 30px;
}
/* modern page 2 */
.modern-about {
    width: 100%;   
    height: auto;
}

.modern-about .col p {
    text-align: justify;
    padding:  0 30px;
}

.modern-about .col h1 {
    text-align: center;
    color: #802652;
}
.modern-about .col:last-child {
    padding: 0;
}
.modern-about .col:last-child {
    width: 100%;
    height: 100%;
}
.modern-about .col:last-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 0 0 0;
}