/* Gallery Styles */
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
    justify-content: center;
  }
  
  .gallery-image {
    width: calc(25% - 10px);
    height: 250px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  
  /* Modal Styles */
  .modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9) !important;
    z-index: 1000 !important;
  }

  .modal-image {
    max-width: 90%; /* Allows responsiveness */
    max-height: 90%;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #ffffff;
    border: none;
    color: #000;
    font-size: 24px;
    cursor: pointer;
  }
  /* Responsive Breakpoints */

/* Medium Devices - Tablets (768px and below) */
@media (max-width: 768px) {
    .gallery-image,
    .gallery-video {
      width: calc(50% - 10px); /* 2 images per row */
    }
  
    .modal-image {
      width: 400px;
      height: 400px;
    }
  }
  
  /* Small Devices - Phones (425px and below) */
  @media (max-width: 425px) {
    .gallery-image,
    .gallery-video {
      width: calc(75% - 10px); /* 1 images per row */
    }
  
    .modal-image {
      width: 320px;
      height: 320px;
    }
  
    .close-button {
      top: 10px;
      right: 10px;
      font-size: 20px;
    }
  }
  
  /* Extra Small Devices - Small Phones (375px and below) */
  @media (max-width: 375px) {
    .gallery-image,
    .gallery-video {
      width: 100%; /* Slightly narrower for smaller phones */
    }
  
    .modal-image {
      width: 300px;
      height: 300px;
    }
  }
  
  /* Extra Extra Small Devices - Very Small Phones (320px and below) */
  @media (max-width: 320px) {
    .gallery-image,
    .gallery-video {
      width: 100%; /* One image per row */
    }
  
    .modal-image {
      width: 280px;
      height: 280px;
    }
  
    .close-button {
      top: 5px;
      right: 5px;
      font-size: 18px;
    }
  }
  