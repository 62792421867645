.training {
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    background-color: #fff;
}
.training-cover {
    width: 80%;
    margin: 0 auto;
    border-radius: 25px;
    background-color: #802652 !important;
}
.training .col p {
    color: white;
    text-align: justify;
}
.training .training-cover {
    background-color: white;
}
.training h1 {
    text-align: center;
    color: white;
}

.training .col:first-child {
    flex: 5
}
.training .col:last-child {
    flex: 7
}

.training .col img {
    border-radius: 25px;
    width: 100pc;
    height: 400px;
}