.success-stories {
    width: 100%;
    height: auto;
}
.success-top {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/dancers.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.success-top-cover {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);  
    padding-top: 200px;  
}
.success-top-cover h1 {
    color: #fff;
    text-align: center;
}
.success-top-cover p {
    color: #fff;
    text-align: center;
} 
.bottom-cover h2 {
    padding: 20px;
    text-align: center;
    color: #802652;
}
.success-desc {
    padding: 0 50px;
}
.success-desc ul{
    text-align: justify;
}
.success-desc li {
    padding: 10px;
}
.success-desc img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 0 1000px;
}
.success-desc b {
    color: #802652;
}