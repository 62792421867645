.festival {
    width: 100%;
    height: auto;
}
.festival-home {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/dance-stage.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-color: black;
}

.festival h1 {
    text-align: center;
    color: pink;
    width: max-content;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    padding: 10px;
    border-radius: 7px;

}

.festival-desc {
    width: 25%;
    border: 1px pink solid;
    position: absolute;
    top: 50%;
    left: 50px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 10px;
    text-indent: 30px;
}
.festival-desc p::first-letter, .festival-right-desc p::first-letter {
    font-size: 50px;
    font-style: italic;
}
.festival-right-desc {
    width: 25%;
    border: 1px pink solid;
    position: absolute;
    top: 50%;
    right: 50px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    text-indent: 30px;
}
/* festival page 2 */
.festival-about {
    width: 100%;   
    height: auto;
}

.festival-about .col p {
    text-align: justify;
    padding:  0 30px;
}

.festival-about .col h1 {
    text-align: center;
    color: #802652;
}
.festival-about .col:last-child {
    padding: 0;
}
.festival-about .col:last-child {
    width: 100%;
    height: 100%;
}
.festival-about .col:last-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 0 0 1000px;
}
h4 {
    text-align: left;
    padding-left: 30px;
}